html {
  font-size: 18px;
  height: 100%;
  min-height: 100%;
}

body {
  font-weight: 400;
  font-family: "Avenir Next", "Luxi Sans", "DejaVu Sans", Tahoma,
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  margin: 0;
  padding: 0;
  background: #f7f8fa;
  height: 100%;
  min-height: 100%;
}
