.spinner {
  transition: opacity 1s;
}

.cursorDefault:hover {
  cursor: default;
}

.cursorPointer:hover {
  cursor: pointer;
}

.datetimeInput input {
  height: 26px;
  padding-left: 6px;
  padding-right: 6px;
}