.spinner {
  transition: opacity 1s;
}

.cursorDefault:hover {
  cursor: default;
}

.cursorPointer:hover {
  cursor: pointer;
}

